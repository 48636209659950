/**
 * Utility providing localization functions.
 */

/**
 * Conditionally prepend locale to a relative path. Non-default locales
 * have the locale name prepended to the path.
 * @param object - a page context
 * @return function
 */
function localizer(ctx) {
  if (ctx && ctx.hasOwnProperty('locale') && ctx.hasOwnProperty('isDefault')) {
    return function(to) { return ctx.isDefault ? to : `/${ctx.locale}${to}`; };
  }
  return function(to) { return to; };
};

/**
 * Determine the locale-specific path given a target language.
 * @param string - the target language or locale
 * @param string - the current path derived from page path (or location.pathname)
 * @param object - a page context
 * @return string or null if no locale change is indicated
 */
function changePath(targetLang, currentpath, ctx) {
  if (ctx.locale === targetLang) return null;
  const prefixLen = currentpath.split('/')[1].length+1;
  // When changing defaultLang to nonDefault, ADD prefix; when nonDefault to default, REMOVE prefix
  return (ctx.isDefault ? `/${targetLang}${currentpath}` : `${currentpath.slice(prefixLen)}`) || '/';
}

export { localizer, changePath };
